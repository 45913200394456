<template>
  <div class="animate-pulse">
    <div
      class="relative grid max-h-[305px] w-full grid-cols-12 gap-x-4 overflow-hidden sm:max-h-max"
    >
      <div
        class="relative col-span-4 sm:col-span-4 sm:row-span-3 lg:row-span-5"
      >
        <div class="relative aspect-[2/3]">
          <div :class="[cssClasses.color, cssClasses.image]" />
        </div>
      </div>

      <div class="col-span-8 col-start-5 self-end sm:col-start-5">
        <div
          class="text-balance break-words pb-4 text-xl font-bold leading-tight sm:text-2xl"
        >
          <div
            class="mb-4 h-6 w-48"
            :class="[cssClasses.color, cssClasses.text]"
          />
        </div>

        <div class="space-y-1">
          <div class="flex space-x-1">
            <div
              class="h-4 w-12"
              :class="[cssClasses.color, cssClasses.text]"
            />
            <div
              class="h-4 w-24"
              :class="[cssClasses.color, cssClasses.text]"
            />
          </div>
          <div class="flex space-x-1">
            <div
              class="h-4 w-16"
              :class="[cssClasses.color, cssClasses.text]"
            />
            <div
              class="h-4 w-32"
              :class="[cssClasses.color, cssClasses.text]"
            />
          </div>
          <div class="flex space-x-1">
            <div
              class="h-4 w-12"
              :class="[cssClasses.color, cssClasses.text]"
            />
            <div
              class="h-4 w-24"
              :class="[cssClasses.color, cssClasses.text]"
            />
          </div>
        </div>
      </div>

      <div
        class="col-span-12 py-6 text-base lg:col-span-8 lg:col-start-5 lg:row-start-4"
      />

      <div
        class="col-span-6 space-y-3 sm:col-span-8 sm:col-start-5 sm:row-start-2 sm:space-y-0"
      />

      <div
        class="col-span-6 flex flex-wrap items-end space-x-1 sm:col-span-8 sm:col-start-5 sm:row-start-3"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { cssClasses } = useSkeleton()

defineOptions({
  name: 'MovieListItemSkeleton',
})
</script>
